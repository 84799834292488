import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import {
  COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT,
} from '@/graphql/_Fragments/CompanyUserRole/ForCommunityUser';
import {
  COMMUNITY_USER_CONNECTION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserConnection/Full';
import { COMMUNITY_USER_TAG_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUserTag/Base';

export const COMMUNITY_USER_PAGINATE_FRAGMENT = `
  fragment communityUserPaginateFragment on CommunityUser {
    uid
    schemaCode
    firstName
    lastName
    name
    email
    jobTitle
    employerName
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    companyRoles(filter: {state: "ACCEPTED"}) {
      ...companyUserRoleForCommunityUserFragment
    }
    _ourConnection(myUid: "%authUser%") {
      ...communityUserConnectionFullFragment
    }
    _myTags(myUid: "%authUser%"){
      ...communityUserTagBaseFragment
    }
    _isFollowed(myUid: "%authUser%")
    _isBookmarked(myUid: "%authUser%")
    _isShared(myUid: "%authUser%")
  }
  ${COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT}
  ${COMMUNITY_USER_CONNECTION_FULL_FRAGMENT}
  ${COMMUNITY_USER_TAG_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
